/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
}


/* input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
} */

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.form-control .multiselect-dropdown .dropdown-btn {
    display: inline-block;
    border:none !important;
    width: 100%;
    padding: 10px !important;
    margin-bottom: 0;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
    color: #6e6e6e !important;
    height: 56px !important;
    text-indent: 0%;
}

.selected-item span.ng-star-inserted{
    margin-right: 25px !important;
    color: white !important;
}

.dropdown-btn .selected-item span.ng-star-inserted{
    margin-right: 10 !important;
    color: white !important;
}

.form-control .multiselect-dropdown .dropdown-btn .selected-item{
    max-width: 250px !important;
    white-space: nowrap;
}

.dropdown-btn  :not(.selected-item) span.ng-star-inserted{
    font-size: 1rem !important;
    margin-right: 20px !important;
    color:red !important;
}